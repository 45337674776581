export const productDetails = {
    "prod_I0pFSNvUoK3RX6": {
        name: "Crucial Health Check",
        descriptions: "The Crucial Health Check is a simple, at-home finger-prick blood test which checks your liver and heart health. Useful for early detection or monitoring of issues that could lead to heart or liver failure.",
        id: "prod_I0pFSNvUoK3RX6",
        imageUrl: "https://files.stripe.com/links/fl_test_OKNo3qNznS3JX3aoUTxw5n3a",
        prices: {
            "onetime": {
                name: "one time",
                price: "$59.00",
                id: "price_1HQnbSJnuJLH689Jly6zd1S7"
            },
            "monthly": {
                name: "monthly",
                price: "$59.00",
                id: "price_1HQndcJnuJLH689J0zS9l7FP"
            },
            "quarterly": {
                name: "quarterly",
                price: "$59.00",
                id: "price_1HQnc3JnuJLH689J77NOtPss"
            },
            "biyearly": {
                name: "biyearly",
                price: "$59.00",
                id: "price_1HQnc2JnuJLH689JKYlk1AZ9"
            }
        }
    },
    "prod_I0pKuYQPvjJygb": {
        name: "Baseline Health Check",
        descriptions: "The Baseline Health Check is a simple, at-home, finger -prick blood test which checks your liver and kidney function, heart health, and risk for diabetes, heart attack, and stroke.",
        id: "prod_I0pKuYQPvjJygb",
        imageUrl: "https://files.stripe.com/links/fl_test_ARgqFVy3ssP4zqRe4O6hFsa5",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1HQng4JnuJLH689JZ7yUw7CZ"
            },
            "monthly": {
                name: "monthly",
                price: "$79.00",
                id: "price_1HQng5JnuJLH689JoY6TQPxS"
            },
            "quarterly": {
                name: "quarterly",
                price: "$79.00",
                id: "price_1HQng4JnuJLH689JS2Vyxao4"
            },
            "biyearly": {
                name: "biyearly",
                price: "$79.00",
                id: "price_1HQng5JnuJLH689Jr16QafSa"
            }
        }
    },
    "prod_I0pMdlQ2kKAtFy": {
        name: "Executive Health Check",
        descriptions: "With the Executive Health Check you get highly accurate results for your liver and kidney function, heart health, inflammation, Vitamin D and the stress marker cortisol.",
        id: "prod_I0pMdlQ2kKAtFy",
        imageUrl: "https://files.stripe.com/links/fl_test_5GPOxxOYOzDJ61zDHIraKZWd",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1HQnhXJnuJLH689Jc2ql9pxS"
            },
            "monthly": {
                name: "monthly",
                price: "$79.00",
                id: "price_1HQnhXJnuJLH689JByPkMyGi"
            },
            "quarterly": {
                name: "quarterly",
                price: "$79.00",
                id: "price_1HQnhXJnuJLH689Juyc21VlK"
            },
            "biyearly": {
                name: "biyearly",
                price: "$79.00",
                id: "price_1HQnhYJnuJLH689JSO3G5mt2"
            }
        }
    },
    "prod_I0pQ98REsFnUJU": {
        name: "Female Wellness",
        descriptions: "Take a deeper look at your thyroid, vitamin D and cortisol levels.",
        id: "prod_I0pQ98REsFnUJU",
        imageUrl: "https://files.stripe.com/links/fl_test_afhi8K6ucx4xQw6pPjXWJlye",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1HQnluJnuJLH689JGpgZsAHS"
            },
            "monthly": {
                name: "monthly",
                price: "$79.00",
                id: "price_1HQnluJnuJLH689J8JmjIr6y"
            },
            "quarterly": {
                name: "quarterly",
                price: "$79.00",
                id: "price_1HQnltJnuJLH689JvZXQlOgJ"
            },
            "biyearly": {
                name: "biyearly",
                price: "$79.00",
                id: "price_1HQnlsJnuJLH689J3zQbot04"
            }
        }
    },
    "prod_I0pTRqJnNp7qoF": {
        name: "Male Wellness",
        descriptions: "Tired, low libido, moody or other urinary symptoms? Just get it checked out bro, from the comfort of your own home.",
        id: "prod_I0pTRqJnNp7qoF",
        imageUrl: "https://files.stripe.com/links/fl_test_vonYaX7dEliTjhqx24sDMF60",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1HQnoVJnuJLH689JK2DO8dq0"
            },
            "monthly": {
                name: "monthly",
                price: "$79.00",
                id: "price_1HQnoUJnuJLH689JGv6Nh70I"
            },
            "quarterly": {
                name: "quarterly",
                price: "$79.00",
                id: "price_1HQnoUJnuJLH689JO03N5vJj"
            },
            "biyearly": {
                name: "biyearly",
                price: "$79.00",
                id: "price_1HQnoTJnuJLH689JEgdIwhfH"
            }
        }
    },
    "prod_I0pV6ezJoKO87r": {
        name: "Overload Health Test",
        descriptions: "Ferritin, Creatinine, hs-CRP, A1B & Glucose. Whether you are a sprinter or concerned about your blood metabolism – this is your test.",
        id: "prod_I0pV6ezJoKO87r",
        imageUrl: "https://files.stripe.com/links/fl_test_RUvyWYhRAV6h2MChpILisckc",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1HQnq5JnuJLH689JtYeAjhPP"
            },
            "monthly": {
                name: "monthly",
                price: "$79.00",
                id: "price_1HQnq6JnuJLH689JdZsQ6e88"
            },
            "quarterly": {
                name: "quarterly",
                price: "$79.00",
                id: "price_1HQnq5JnuJLH689JBhMATlmU"
            },
            "biyearly": {
                name: "biyearly",
                price: "$79.00",
                id: "price_1HQnq5JnuJLH689JMOmP3ZqR"
            }
        }
    },
}